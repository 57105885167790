<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Market Area: <small>{{ market_area.name }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors" />

    <div class="form-group text-left">
      <label for="market_area_name">Name</label>
      <input type="text" class="form-control" id="market_area_name" v-model="market_area.name" placeholder="Market Area Name">
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <button @click="$router.go(-1)" class="btn btn-secondary">Cancel</button>
        <button v-on:click="createMarketArea" type="button" class="btn btn-success">Create MarketArea</button>
      </div>
    </div>
  </div>
</template>

<script>
import { MarketAreaService } from '@/common/services/market_area.service';
import DangerAlert from '@/common/components/danger-alert.vue';

export default {
  name: 'MarketAreaNew',
  components: {
    'danger-alert': DangerAlert,
  },
  data() {
    return {
      market_area: {
        name: '',
      },
      errors: [],
    };
  },
  methods: {
    createMarketArea() {
      MarketAreaService.create(this.market_area)
        .then((response) => {
          this.$router.push({ name: 'MarketAreaShow', params: { id: response.data.id } }, () => {
            this.$notify({
              title: 'Created Activity',
              text: `Successfully created ${response.data.name}!`,
              type: 'success',
              width: 350,
            });
          });
        })
        .catch((error) => {
          window.scrollTo(0, document.getElementById('top').offsetTop);
          this.errors = error.response.data.error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/market_areas";
</style>
